<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row mb-3">
          <label class="col-sm-2 col-form-label">Project Code</label>
          <div class="col-sm-10">
            <b-form-select v-model="code" class="mb-3">
              <b-form-select-option
                v-for="proj in this.$store.getters.getterProjectChange"
                v-bind:key="proj.code"
                :value="proj.code"
              >
                {{ proj.code + " - " + proj.nickname }}
              </b-form-select-option>
            </b-form-select>
          </div>
        </div>

        <div class="row mb-3 mt-5">
          <label class="col-sm-2 col-form-label"
            >Change Management Number</label
          >
          <div class="col-sm-10">
            <b-form-input
              id="input-large"
              size="md"
              placeholder="Change Management Number"
              v-model="cr_number"
            ></b-form-input>
          </div>
        </div>

        <div class="row mb-3 mt-5">
          <label class="col-sm-2 col-form-label">Title</label>
          <div class="col-sm-10">
            <b-form-input
              id="input-large"
              size="md"
              placeholder="Title"
              v-model="title"
            ></b-form-input>
          </div>
        </div>

        <div class="row mb-3 mt-5">
          <label class="col-sm-2 col-form-label">Date</label>
          <div class="col-sm-10">
            <b-input-group>
              <input
                class="form-control"
                type="text"
                v-model="date"
                id="example-date-input"
                placeholder="YYYY-MM-DD"
              />
              <b-input-group-append>
                <b-form-datepicker
                  button-only
                  right
                  reset-button
                  reset-value=""
                  v-model="date"
                  :no-flip="true"
                ></b-form-datepicker>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="row mb-3 mt-5">
          <label class="col-sm-2 col-form-label">Type</label>
          <div class="col-sm-10">
            <b-select
              :options="formattedTypeOptions"
              size="md"
              v-model="type"
            ></b-select>
          </div>
        </div>

        <div class="row mb-3 mt-5">
          <label class="col-sm-2 col-form-label">File</label>
          <div class="col-sm-10">
            <b-form-file id="file-large" size="md" v-model="file"></b-form-file>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn btn-primary btn-md" @click.prevent="save">
            <div
              class="text-center"
              v-bind:class="{
                'd-none': isLoading,
              }"
            >
              <b-spinner
                style="width: 1rem; height: 1rem;"
                variant="light"
                label="Spinning"
              ></b-spinner>
            </div>
            <div
              class="text-center"
              v-bind:class="{
                'd-none': textButton,
              }"
            >
              Save
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  GET_PROJECT_CHANGE,
  POST_CHANGE_MANAGEMENT,
} from "@/core/services/store/change-management.module";
import ApiService from "../../../core/services/api.service";
import { showToast } from "../../../core/helper/toast.helper";
import { formattedOptions } from "../../../core/helper/array-validation.helper";

export default {
  name: "change-management",
  data() {
    return {
      cr_number: "",
      title: "",
      code: "",
      date: "",
      textButton: false,
      isLoading: true,
      type: null,
      file: null,
      options: {
        types: [],
      },
    };
  },
  computed: {
    formattedTypeOptions() {
      return formattedOptions(this.options.types);
    },
  },
  methods: {
    async save() {
      var cr = this.cr_number;
      var title = this.title;
      var code = this.code;
      var date = this.date;

      var split = date.split("-");
      var fixDate = split[2] + "-" + split[1] + "-" + split[0];

      var send = {
        project: {
          code: code,
        },
        cr_number: cr,
        title: title,
        date: fixDate,
        type: this.type
          ? this.options.types.find((type) => type.code === this.type)
          : null,
      };

      this.textButton = true;
      this.isLoading = false;
      await this.$store.dispatch(POST_CHANGE_MANAGEMENT, {
        ...send,
        file: this.file,
      });
      var result = this.$store.getters.getterResultSaveChange;
      console.log("result view", result);
      if (result.id == null) {
        this.$bvToast.toast(result.msg, {
          title: "Warning",
          variant: "danger",
          solid: true,
        });
      } else {
        this.$bvToast.toast("Data Saved Successfully", {
          title: "Success",
          variant: "success",
          solid: true,
        });

        var url = "/project/change/management/" + result.id + "/detail";
        this.$router.push(url);
      }
      this.textButton = false;
      this.isLoading = true;
    },

    getChangeManagementType() {
      ApiService.setHeader();

      ApiService.query("change/management/type")
        .then((response) => {
          this.options.types = response.data.data;
        })
        .catch(() => {
          showToast("Error", "Failed get change management type", "danger");
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Change Management" }]);

    this.$store.dispatch(GET_PROJECT_CHANGE);

    this.getChangeManagementType();
  },
};
</script>

<style></style>
